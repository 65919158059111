.beerGridName {
    font-family: 'KCIllHand', sans-serif;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    transition: 0.2s;
    /*letter-spacing: 0.5px;*/
}
.beerName {
    /*font-weight: bold;*/
    line-height: 1;
    margin: 0 10px 0 10px;
    font-size: 1.4vmin;
}
.beerBrewery {
    margin: 0 10px 0 10px;
    font-size: 1.0vmin;
}
.beerNameDivider {
    height: 0.5px;
    width: calc(100% - 12px);
    background-color: #074524;
    margin: 2px 6px
}
.beerInfo {
    width: calc(100% - 20px);
    display: flex;
    justify-content: space-between;
    font-size: 1vmin;
    margin: 3px 10px 3px 10px;
}
.loadingTextPlaceholder {
    line-height: 1;
    padding: 0;
    margin: 2px 8px 2px 8px;
    height: 10px;
    width: calc(100% - 16px);
    background-color: #ccc;
    animation: loadingImg 1s ease-in-out infinite;
}
.loadingTextPlaceholder:nth-child(3) {
    width: calc(100% - 16px - 8px);
}