@media screen and (max-width: 1400px) and (min-width: 640px) {
    .quizDialogInnerContainer h1 {
        font-size: 60px;
    }
    .quizFieldsContainer input, .quizFieldsContainer select {
        font-size: 24px !important;
    }
    .teamTitle {
        font-size: 22px !important;
    }
    .quizBtn {
        font-size: 28px !important;
    }
    .bookAnotherContainer {
        font-size: 22px !important;
    }
    .contactQuiz {
        font-size: 20px !important;
    }
}
