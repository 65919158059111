.tableInfoContainer {
    position: fixed;
    border-radius: 10px;
}

.tableInfo {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 5px 2px 2px 2px;
    text-align: start;
    font-size: 12px;
    gap: 5px;
    transition: 0.2s;
    align-items: center;
    height: calc(100% - 10px);
}
.tableIdInfo {
    overflow: hidden;
    font-size: 11px;
    /*position: relative;*/
    /*transform: translateZ(0);*/
}
.tableIdInfo, .teamInfo {
    transition: 0.2s;
}
.tableInfo input {
    margin: 0;
}

.swapTeamBtn {
    transition: 0.2s;
    font-size: 9px;
    padding: 0;
    margin: 0;
}

.teamInfoItem {
    background-color: #f1f1f1;
    border: 1px solid #ccc;
    padding: 1px 3px;
    border-radius: 5px;
}