.bookingSuccessContainer {
    width: 86%;
}

.quizSuccessImg {
    max-width: 450px !important;
    width: 45%;
    height: auto;
}

.quizBottomImg {
    margin: 20px 0 20px 0;
    width: 60%;
    max-width: 550px;
}

.bookingDetailsMessage h3 {
    margin-bottom: 10px;
}

.bookingDetailsMessage .message {
    margin-bottom: 20px;
}