.quizDialogContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 100;
    cursor: pointer;
    pointer-events: visiblePainted;
    transition: opacity 0.25s, height 0.2s;
}

.quizDialogInnerContainer {
    position: relative;
    overflow-x: hidden;
    display: block;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0px;
    border: #DB5791 calc(11vh / 15) solid;
    border-radius: 25px;
    background-color: #fefefe;
    width: 50svw;
    overflow-y: auto;
    height: calc(80svh - 70px);
    cursor: auto;
    transition: margin-top 0.75s;
}

/*.quizDialogInnerContainer div:first-child {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    justify-content: space-between;*/
/*    margin-top: 0px;*/
/*    overflow-y: auto;*/
/*    padding-top: 35px;*/
/*    padding-bottom: 35px;*/
/*    width: 50svw;*/
/*}*/

.quizBookingSection {
    align-items: center;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
    padding-top: 35px;
    padding-bottom: 35px;
    height: calc(100% - 70px) !important;
    width: 50svw;
    transition: translate 0.5s;
}

.calendarBtn {
    font-family: KCIllHand, sans-serif;
    font-size: 2rem !important;
}
.googleCal {
    background-color: #1f87fc !important;
}
.iCal {
    background-color: #8c8c8c !important;
}
.otherCal {
    background-color: #3f3f3f !important;
}

.calendarBtnContainer {
    justify-content: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}
/*.bookingDetailsMessage span {*/
/*    font-size: 2rem;*/
/*    font-family: KCIllHand, sans-serif;*/
/*}*/