.quizConfirmBottomContainer {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    gap: 15px;
}
.quizConfirmBtnContainer {
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 86%;
    gap: 15px;
}
.quizConfirmBtnContainer button {
    width: 100%
}
.bookingDetailsMessage {
    font-size: 2rem;
    font-family: Bely, sans-serif;
}
.detailsContainer {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    /*padding-bottom: 35px;*/
    gap: 10px;
}
.bookingDetailsContainer {
    display: flex;
    flex-direction: column;
    align-items: start;
    font-size: 2rem !important;
    font-family: Bely, sans-serif;
}

.bookingDetailsInnerContainer {
    gap: 25px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 86%;
}

.loadingConfirmContainer {
    z-index: 10;
    margin-top: -35px;
    padding-bottom: 95px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 100%;
}